@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-facebook div {
  @apply bg-[#707275] dark:bg-white;
  display: inline-block;
  position: absolute;
  left: 2px;
  width: 4px;
  // background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 2px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 8px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 14px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 2px;
    height: 4px;
  }
  50%, 100% {
    top: 6px;
    height: 8px;
  }
}

.rti--container {
  width: 100%;
  background-color: transparent;
}

.rhap_container {
  @apply w-full h-9 px-3 py-0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  max-width: 400px;
}

// .rhap_progress-section,
.rhap_additional-controls,
.rhap_main-controls {
  display: none;
}

.rhap_time {
  color: #7f7f7f !important;
}

.rhap_main {
  @apply h-full;
  height: 100% !important;
}

.rhap_repeat-button,
.rhap_repeat-button > svg {
  width: 18px;
  height: 18px;
}

.rhap_play-pause-button,
.rhap_play-pause-button > svg {
  width: 24px;
  height: 24px;
}

.dashboard-section {
  max-width: 88vw;
}
